.footer {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  height: 88px;
  flex: 0 0 88px;
  align-self: flex-end;
  font-size: 14px;
  font-weight: normal;
  color: #a1a6b4;
  padding: 0 120px;
  align-items: center;
  justify-content: flex-end;

  .copyright {
    min-width: 195px;
  }
}
