.team-item {
  border-radius: 3px;
  padding: 0px 55px 0px 0px;
  margin-bottom: 0;
  position: relative;
  display: flex;
  align-items: center;
  &_wrapper {
    padding-left: 10px;
  }
  .alert-icon {
    width: 16px;
    height: 16px;
    margin-left: 10px;
  }
  &_btn-toggle {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -24px;
    border: none;
    background: none;
    padding: 0;
  }
}
