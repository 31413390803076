.hint {
  padding-left: 24px;
  font-size: 14px;
  line-height: 1.5;
  color: #a1a6b4;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 0 0 25px;

  img {
    position: absolute;
    left: 2px;
    top: 50%;
    transform: translateY(-50%);
  }

  &-register {
    margin-top: 30px;
    
    a {
      color: #a1a6b4;
    }

    img {
      top: 8px;
    }
  }
}
