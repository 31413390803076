.button-new {
  font-family: 'Open Sans', sans-serif;
  height: 40px;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  position: relative;
  min-width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  &_white {
    background-color: transparent;
    font-size: 16px;
  }
  &_primary-light {
    border: none;
    background-color: rgba(41, 204, 171, 0.15);
    width: 96px;
    min-width: 96px;
    padding: 0;
    height: 32px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 600;
  }
}

.rc-slider-custom {

}

.rc-slider-custom.rc-slider-disabled {
  background: none;
}
