body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  color: #6b78a4;
  letter-spacing: normal;
}

html {
  overflow-y: inherit;
}

h1 {
  font-family: 'Poppins', sans-serif;
}

h2 {
  font-family: 'Poppins', sans-serif;
}

h3 {
  font-family: 'Poppins', sans-serif;
}

h4 {
  font-family: 'Poppins', sans-serif;
}

h5 {
  font-family: 'Poppins', sans-serif;
}

h6 {
  font-family: 'Poppins', sans-serif;
}

p {
  font-family: 'Poppins', sans-serif;
}

span, input, textarea, keygen, select, button {
  font-family: 'Poppins', sans-serif;
}

textarea {
  resize: none;
}

.app-fixed {
  overflow: hidden;
  height: 100vh;
  z-index: 100 !important;
}

.app-loading {
  background: #f8fafb;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 88px);
  z-index: 100 !important;
}

.error {
  color: #f81654;
  font-size: 14px;
  display: flex;
  align-items: flex-start;
  padding: 10px 0px;
  position: absolute;
}

button,
input {
  outline: none;
}

.text-error {
  color: #f81654;
}

.hint {
  display: flex;
  align-items: flex-start;
  padding: 10px 0px;
}

.error img,
.hint img {
  margin-right: 7px;
  height: 16px;
  width: 16px;
}

.error p,
.hint p {
  margin: 0;
  text-align: left;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #a1a6b4;
  margin-top: -2px;
}

.error p {
  color: #f81654;
}

.ql-align-right {
  text-align: right;
}

.ql-align-center {
  text-align: center;
}

.ql-align-left {
  text-align: left;
}

@font-face {
  font-family: 'SignPainter-HouseScript';
  src:local('SignPainter-HouseScript'), url(fonts/SignPainter-HouseScript.ttf) format('truetype');
}
