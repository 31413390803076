.settings {
  &-profile {
    &_avatar {
      width: 111px;
      height: 111px;
      min-width: 111px;
      min-height: 111px;
      border-radius: 24px;
      background-color: #ecedf0;
      margin-right: 30px;
      background-position: center;
      background-size: cover;
      position: relative;
      background-image: url('../../../assets/user-default.svg');

      .loader {
        top: 45px;
      }

      label {
        cursor: pointer;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      label > * {
        pointer-events: none;
      }

      .upload-button-file {
        width: 0.1%;
        height: 0.1%;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -100000000;
      }
    }

    &_label {
      font-size: 12px;
      font-weight: 500;
      color: #a1a6b4;
      text-transform: uppercase;
      margin-bottom: 4px;
    }

    &_fields {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }

    &_col {
      width: 32%;
      align-items: center;
      margin-bottom: 30px;
      position: relative;

      .saved {
        position: absolute;
        top: 35%;
        right: 1%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 500;
        color: #3971e9;
        width: 64px;
        height: 32px;
        border-radius: 3px;
        background-color: rgba(41, 204, 171, 0.15);
      }

      .input {
        background-color: transparent;
        border: none;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        color: #333333;
        padding: 7px 0;
        display: flex;
        flex-grow: 1;

        &:focus {
          color: #3971e9;
          border-color: #3971e9;
        }
      }

      .readonly {
        &_label {
          padding-top: 4px;
          margin-bottom: 1px;
        }

        &_text {
          padding: 7px 0;
        }
      }
    }
  }

  &-language {
    &_select {
      width: 192px;
    }
  }
}
