.rc-switch {
  margin-right: 16px;

  &-checked {
    border: solid 1px var(--company-color);
    background-color: var(--company-color);
  }

  &-disabled {
    border: solid 1px #cccccc !important;
    background-color: #cccccc !important;
  }
}
