@import '~/styles/colors.scss';

button {
  background: transparent;
  outline: none;
  border: none;
}
.react-datepicker-popper {
  z-index: 1000 !important;
  left: -26px !important;
}

.react-datepicker__navigation {
  overflow: visible;
}

.react-datepicker__time-list {
  padding: 0;
}

.react-datepicker__year .react-datepicker__year-text {
  width: 56px;
}

.datepicker-new {
  border-radius: 6px;
  height: 48px;
  background-color: transparent;
  border: solid 1px #c7cfe0;
  font-size: 16px;
  font-family: 'OpenSans', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  color: #70747f;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  //background-image: url("../../assets/ic-event-note-24-px-copy-3.svg");
  background-repeat: no-repeat;
  background-position: 92%;
  &:disabled {
    background-color: $disabled-color;
    cursor: no-drop;
  }

  &_error {
    border-color: #f81654;
  }

  &::placeholder {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    color: #a1a6b4 !important;
  }

  &_group {
    display: flex;
  }

  &_label {
    border-radius: 3px;
    background-color: #f8f8f8;
    border: solid 1px #babdc7;
    padding: 12px 17px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    height: 22px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(51, 51, 51, 0.8) !important;
  }
}

.event-icon {
  position: absolute;
  top: 12px;
  right: 17px;
  cursor: pointer;
}

.custom-date-picker {
  position: relative;
  display: flex;

  & > button {
    position: absolute;
    top: calc(50% - 12px);
  }

  &-inlined {
    position: relative;

    .datepicker-new {
      width: 10px;
      padding: 9px 9px;
      background-color: transparent;
      border: none;
      cursor: pointer;
      margin-left: 10px;
    }

    .react-datepicker-popper {
      left: -140px !important;
    }

    .event-icon {
      position: absolute;
      top: 12px;
      right: 12px;
      z-index: -10;
    }
  }
}

.react-datepicker-wrapper {
  display: flex;
}
.react-datepicker__input-container {
  display: flex;
  width: 100%;
}

.react-datepicker__week {
  .react-datepicker__day--in-range,
  .react-datepicker__day--keyboard-selected {
    background-color: var(--company-color) !important;

    &:hover {
      background-color: $background !important;
    }
  }

  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
    background-color: rgba(41, 204, 171, 0.53) !important;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range {
    background-color: var(--company-color) !important;

    &:hover {
      background-color: $background !important;
    }
  }
}

.react-datepicker__month--selecting-range
  .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range) {
  color: #ccc !important;
}
