.editor {
  height: 100%;
  width: 100%;
  white-space: pre-wrap;

  .ql-container {
    height: 100%;
    min-height: 120px;
    max-height: 130px;

    .ql-editor {
      min-height: 120px;
      max-height: 130px;
      overflow-y: auto;
      .ql-blank::before {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #99a5be;
      }
      li {
        padding: 0 16px;
      }
      ul {
        padding-left: 0;
        padding-top: 12px;
      }
      ol {
        padding-left: 0;
        padding-top: 12px;
      }
    }
  }

  .ql-editor.ql-blank::before {
    font-family: 'Poppins', sans-serif;
    color: #6b78a4;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
  }
  .ql-toolbar {
    & + .ql-container {
      height: calc(100% - 42px);
    }
  }
}
