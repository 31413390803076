.multi-rate-modal-new {
  .step {
    &_skill {
      box-shadow: inset 0 -0.5px 0 0 #ccced5;
      background-color: #ecedf0;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
      color: #333333;
      padding: 16px 16px;
      box-sizing: border-box;
    }
    &_focusArea {
      box-shadow: inset 0 -0.5px 0 0 #ccced5;
      background-color: #ffffff;
      font-size: 16px;
      line-height: 1.25;
      color: #333333;
      padding: 17px 16px;
      box-sizing: border-box;
      & > div:first-child {
        min-width: 200px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 200px;
        margin-right: 10px;
      }
    }
    &_slider {
      display: flex;
      align-items: center;
      margin-top: 30px;
      &-value {
        font-size: 20px;
        font-weight: 500;
        text-align: right;
        color: #333333;
        min-width: 50px;
      }
    }
    &_scaling {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px 0 30px;
      &-icon {
        width: 24px;
        height: 24px;
      }
      &-text {
        font-size: 20px;
        font-weight: 500;
        font-style: italic;
        line-height: 1.2;
        color: #333333;
        margin-left: 6px;
      }
    }
    &_textarea {
      height: 129px;
      margin-bottom: 10px;
    }
    &_my-rate {
      margin-top: 30px;
      .step_focusArea {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      &-explanation {
        font-size: 14px;
        font-style: italic;
        line-height: 1.43;
        color: #333333;
      }
      &-rate {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        margin-left: auto;
      }
    }
    &_other-rate {
      margin-top: 30px;
      .step_skill {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .step_focusArea {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      &-progress {
        & > div {
          display: flex;
          align-items: center;
          .average {
            font-size: 14px;
            font-weight: 500;
            text-align: right;
            color: #333333;
            margin-right: 8px;
          }
        }
        .average-info {
          font-size: 12px;
          color: #333333;
          margin-top: 12px;
        }
      }
    }
    &_request {
      &-status {
        font-size: 14px;
        font-style: italic;
        text-align: right;
        &.rejected,
        &.cancelled {
          color: #f81654;
        }
        &.requested {
          color: #29ccab;
        }
      }
    }
  }
}
