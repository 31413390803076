@import '~/styles/colors.scss';

$input-vert-padding: 8px;
$input-horz-padding: 15px;
$input-horz-with-icon-padding: 45px;

.search {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;

  &_input {
    border-radius: 6px;
    background-color: #ffffff;
    padding: $input-vert-padding calc(#{$input-horz-padding}-1px) $input-vert-padding;
    line-height: 24px;
    font-size: 14px;
    margin: 0;
    width: 100%;
    box-sizing: border-box;
    min-height: 30px;
    height: 40px;

    &_error {
      border-color: #f81654;
    }

    &:disabled {
      background-color: $disabled-color;
    }

    &-hint {
      padding-left: 24px;
      font-size: 14px;
      line-height: 1.5;
      color: #a1a6b4;
      position: relative;
      margin-top: 12px;

      &_high {
        margin-top: -12px;
        margin-bottom: 24px;
      }
    }
  }

  &_input-with-icon {
    padding: $input-vert-padding calc(#{$input-horz-with-icon-padding} - 1px) $input-vert-padding;
    // width: calc(100% - #{$input-horz-with-icon-padding} * 2);
  }
}
