.new-team-modal {
  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 500px;
    min-width: auto;
    &_content {
      height: auto;
      overflow: auto;
    }
    &_subtitle {
      margin: 0 0 10px 0;
    }
  }
}
